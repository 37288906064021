export type fileAttachment = {
    fileName: string;
    fileType: string;
    hashValue?: string;
};

export class AnnuityUserInputModel {
    state: string;
    premium: number;
    age: number;
    gender: 'M' | 'F';
    jointAge: number;
    jointGender: 'M' | 'F';
    cusip?: string;
    allocations: Map<string, number> | {[key: string]: number};
    brokerDealerName: string;
    oesBrokerDealerName: string;
    productType?: string;
    fileAttachments?: fileAttachment[];

    // ids
    icRider: string[];
    // ids
    dbRider: string[];

    toPost() {
        return {
            ...this,
            allocations: this.formatAllocationsForPost(),
        };
    }

    private formatAllocationsForPost() {
        if (this.allocations instanceof Map) {
            return Object.fromEntries(this.allocations);
        }

        return this.allocations;
    }

}
