import * as moment from 'moment';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
    AnnuityRate,
    AnnuityRider, DeathBenefitRider,
    GeneralAnnuityProduct,
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-mapper.service';
import { Moment } from 'moment';
import { Utilities } from '@common/utilities/utilities';
import { AnnuityIllustrationSummaryModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-illustration-summary/annuity-illustration-summary.model';
import { MatDialogConfig } from '@angular/material/dialog';
import { RateGroup, RateGroupData } from '@product-marketplace/annuity-product/annuity-helper.service';
import { AnnuityIndicesModel } from './annuity-product-view/annuity-models/annuity-indices.model';
import { AnnuityAllocationTableModel } from '@product-marketplace/annuity-product/annuity-product-view/shared-annuity-components/annuity-allocation-table/annuity-allocation-table.model';
import { FirelightEmbeddedComponent } from './annuity-product-view/firelight/firelight-embedded/firelight-embedded.component';
import {
    ANNUITY_PRODUCT_TYPES,
    ANNUITY_SHELL_PRODUCT,
    EXCHANGE_MAX_AGE,
    EXCHANGE_MIN_AGE,
    EXCHANGE_STRATEGY_MAX
} from '@product-marketplace/annuity-product/annuity-constants';
import { AnimatedDialogConfig, AnimationDirections } from '@common/services/animated-dialog.service';

export class AnnuityUtils {
    static readonly ORDER_ENTRY_SYSTEM_PREFIXES = {
        FIRELIGHT: {
            prefix: 'FireLight',
            component: FirelightEmbeddedComponent
        },
        EBIX: {
            prefix: 'Ebix',
            component: null, // we do not use an embedded component for this, it's a new window
        },
        FIG: {
            prefix: 'FIG_OES',
            component: null, // we do not use an embedded component for this, it's a new window
        }
    };

    static get EXCHANGE_INVALID_STRATEGY_COUNT_MSG() {
        const msg = `<Placeholder>Only Up to ${EXCHANGE_STRATEGY_MAX} strategies can be selected for the analysis`;
        return this.EXCHANGE_DIALOG_CONFIG(msg);
    }

    static get EXCHANGE_INVALID_RANGE_MSG() {
        const msg = `We can only run analytics on ages: ${EXCHANGE_MIN_AGE} to ${EXCHANGE_MAX_AGE}`;
        return this.EXCHANGE_DIALOG_CONFIG(msg);
    }

    static isVaProduct(annuityProduct: GeneralAnnuityProduct) {
        return annuityProduct?.productType === ANNUITY_PRODUCT_TYPES.VARIABLE_ANNUITIES;
    }

    static EXCHANGE_DIALOG_CONFIG(msg, lumaContact = false): MatDialogConfig {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.data = {
            singleButton: true,
            title: 'Unable To Proceed',
            message: msg,
            lumaContact
        };
        return dialogConfig;
    }

    static isRateGroup = (r): r is RateGroup => r.data === undefined;
    static isRateGroupData = (r): r is RateGroupData => !AnnuityUtils.isRateGroup(r);

    static getStartingIncomeDate() {
        return  moment(new Date()).add(4, 'week').toDate();
    }

    static getIncomeAgesValidators(annuityRider: AnnuityRider, primaryAgeCtrl: AbstractControl, jointAgeCtrl: AbstractControl, annuityProduct: GeneralAnnuityProduct) {
        return this.getIncomeAgeValidatorsFromMultiple([annuityRider], primaryAgeCtrl, jointAgeCtrl, [annuityProduct]);
    }

    static getIncomeAgeValidatorsFromMultiple(annuityRiders: AnnuityRider[], primaryAgeCtrl: AbstractControl, jointAgeCtrl: AbstractControl, annuityProducts: GeneralAnnuityProduct[]) {
        const primaryAge = +primaryAgeCtrl.value;
        const jointAge = jointAgeCtrl.valid && jointAgeCtrl.value != null ? +jointAgeCtrl.value : null;
        const age = !!jointAge && (primaryAge > jointAge) ? jointAge : primaryAge;
        let maxAtznAge;
        let minAge;
        let maxAge;
        const anyVaProducts = annuityProducts?.some(product => product.productType === ANNUITY_PRODUCT_TYPES.VARIABLE_ANNUITIES);
        if (annuityRiders.length === 0 && !anyVaProducts) {
            return null;
        }

        annuityProducts.forEach(annuityProduct => {
            if (!maxAtznAge || maxAtznAge < annuityProduct.maxAtznAge) {
                maxAtznAge = +annuityProduct.maxAtznAge;
            }

            if (!minAge || minAge < +annuityProduct.minAge) {
                minAge = +annuityProduct.minAge;
            }
            if(!AnnuityUtils.isVaProduct(annuityProduct)) {
                if (!maxAge || maxAge > +annuityProduct.maxAge) {
                    maxAge = +annuityProduct.maxAge;
                }
            }

        });

        annuityRiders.forEach(annuityRider => {
            if (!minAge || minAge < annuityRider.incomeStartMinAge ) {
                minAge = +annuityRider.incomeStartMinAge;
            }

            if (!maxAge || +maxAge < +annuityRider.incomeStartMaxAge) {
                maxAge = +annuityRider.incomeStartMaxAge > +maxAtznAge ? +maxAtznAge : +annuityRider.incomeStartMaxAge;
            }
        });

        return [
            Validators.required,
            Validators.min(minAge),
            Validators.max(maxAge),
            this.validateIncomeStartAge(annuityRiders, age),
            this.validateFutureIncomeStartAge(primaryAge, jointAge, maxAge),
        ];
    }

    // TODO - Date Validation
    static dateFilterBeforeToday(date: Date): boolean {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        return date.getTime() >= today.getTime();
    }

    // TODO - Is this needed
    static dateFilterBetweenDates(date: Date, startDate: Date, endDate: Date): boolean {
        return (!startDate || date.getTime() > startDate.getTime()) && (!endDate || date.getTime() <= endDate.getTime());
    }

    static incomeStartingAgeFromDate(age: number, date: Date) {
        const yearDifference = moment(date).diff(moment(), 'years');
        return (age + yearDifference);
    }

    static getAgeFromDate(date: Date | Moment): number {
        return moment().diff(moment(date), 'years');
    }

    static validateIncomeStartAge = (annuityRiders: AnnuityRider[], age: number): ValidatorFn => {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const startingAge = +control.value;
            let refMin;
            let refMax;
            annuityRiders.forEach(annuityRider => {
                if (!refMin || refMin < +annuityRider.incomeRefMinAge) {
                    refMin = +annuityRider.incomeRefMinAge;
                }

                if (!refMax || refMax > +annuityRider.incomeRefMaxAge) {
                    refMax = +annuityRider.incomeRefMaxAge;
                }
            });

            if (startingAge < age) {
                return {
                    invalidAge: `Start age is below current age`
                };
            } else if ((startingAge - age) > refMax) {
                return {
                    invalidAge: `Max deferral is ${refMax}`
                };
            } else if ((startingAge - age) < refMin) {
                return {
                    invalidAge: `Min deferral is ${refMin}`
                };
            }

            return null;
        };
    }

    static validateFutureIncomeStartAge = (primaryAge: number, secondaryAge: number, maxAge: number): ValidatorFn => {
        return (control: AbstractControl): {[key: string]: any} | null => {
            const startingAge = +control.value;

            if (secondaryAge && primaryAge !== secondaryAge) {

                if (primaryAge > secondaryAge && secondaryAge < startingAge) {
                    const startingAgePrimary = (startingAge - secondaryAge) + primaryAge;
                    if (startingAgePrimary > maxAge) {
                        return {
                            maxAnnuitizationAge: `Primary Age (${startingAgePrimary}) Exceeds Max Annuitization Age: ${maxAge}`
                        };
                    }

                }
                // TODO - Logic for secondary if we decide to plug this in
                // else if (secondaryAge > primaryAge && primaryAge < startingAge) {
                //     const startingAgeSecondary = (startingAge - primaryAge) + secondaryAge;
                //     if (startingAgeSecondary > maxAge) {
                //         return {
                //             maxAnnuitizationAge: `Secondary Age (${startingAgeSecondary}) Exceeds Max Annuitization Age: ${maxAge}`
                //         };
                //     }
                // }
            }

            return null;
        };
    }


    // START OF RIP OUT

    static ANNUITY_AGE_CONTROL_MSG = (ctrl: AbstractControl, prependStr = '') => {
        let errMsg = '';
        const {required, min, max, invalidAge, maxAnnuitizationAge} = ctrl.errors;
        if (required) {
            errMsg = 'Age is Required';
        } else if(min) {
            errMsg = `Min Age is ${min.min}`;
        } else if (max) {
            errMsg = `Max Age is ${max.max}`;
        } else if (invalidAge) {
            errMsg = invalidAge;
        } else if(maxAnnuitizationAge) {
            errMsg = maxAnnuitizationAge;
        }

        if (prependStr && errMsg !== invalidAge) {
            errMsg = `${prependStr.trim()} ${errMsg}`;
        }

        return errMsg;
    }

    static GET_TIME_HORIZON_YEARS(request) {
        const TIME_HORIZON_CAP = 100; // Cap No Rider
        const TIME_HORIZON_CAP_WITH_RIDER = 95; // Cap with Rider

        // Starting Ages
        const primaryAge = request.primaryAge;
        const jointAge = request.jointAge;

        // Setting cap based on if rider is selected or not
        const timeHorizonCap = !!request.incomeRider ? TIME_HORIZON_CAP_WITH_RIDER : TIME_HORIZON_CAP;

        // Set primary age to current youngest age
        let youngestAge = primaryAge;

        if (request.jointToggled) {
            // Use joint age if it younger than primary age
            if (request.jointAgeValid && jointAge < primaryAge) {
                youngestAge = jointAge;
            }
        }

        // Set max time horizon based on Cap minus youngest age
        const maxTimeHorizon = timeHorizonCap - youngestAge;

        // Get Chart Dates and converts to Moment objects
        const chartToMoment = moment(request.toDate);
        const chartFromMoment = moment(request.fromDate);

        // Compare Chart Differences in years, uses precision (1.5, etc)
        let difference = chartToMoment.diff(chartFromMoment, 'years', true);

        // If the dates start/end on the same month - the THY may need to be padded one year
        if (difference % 1 === 0) {
            /**
             * If the date range is set to the max - we need to pad one year for when Cannex's API expands the dates out
             * Min From: 12/2008 will be ran as 11/2008 in Cannex
             * Max To:   12/2020 will be ran as 01/2021 in Cannex
             * This expands from 12 years to 13 years on Cannex's end since THY is a integer
             * We currently do not pad max range values on our request because those dates are already padded in:
             * AnnuityHelperService.getDatesFromAllocatedTables using AnnuityUtils.momentOffset to properly set a min/max date
             */

            const isFromMax = moment(request.fromDate).isSame(request.minFromDate);
            const isToMax = moment(request.toDate).isSame(request.maxFromDate);
            if (isFromMax && isToMax) {
                difference++;
            }
        }

        // Rounds up to nearest whole number
        const yearsDifference = Math.ceil(difference);

        // Sets time horizon to chart years difference for now
        let timeHorizonYears = request.incomeRider ? maxTimeHorizon : yearsDifference;
        let newMaxDate: Date;

        // If time horizon or years difference is larger than max time horizon, truncate max years
        if (timeHorizonYears > maxTimeHorizon || yearsDifference > maxTimeHorizon) {
            timeHorizonYears = maxTimeHorizon;
            newMaxDate = chartFromMoment.clone().add(maxTimeHorizon, 'years').toDate();
        }

        // only returns defined if years difference is larger than max time horizon
        return {
            timeHorizonYears,
            newMaxDate,
        };
    }

    static GET_STARTING_AGES_FROM_INPUTS(request: FormGroup | object) {
        const formData = request instanceof FormGroup ? request.getRawValue() : request;

        const primaryAge = +formData.ageControl;
        const jointAge = formData.jointToggleControl ? +formData.jointAgeControl : null;
        const incomeStartAge = +formData.incomeStartAgeControl;

        let deferralTimeYrs: number;
        let startingPrimaryAge = null;
        let startingJointAge = null;

        if (jointAge != null) {
            if (primaryAge > jointAge) {
                startingJointAge = incomeStartAge;
                deferralTimeYrs = (incomeStartAge - jointAge);
                startingPrimaryAge = (incomeStartAge - jointAge) + primaryAge;
            } else {
                startingPrimaryAge = incomeStartAge;
                deferralTimeYrs = (incomeStartAge - primaryAge);
                startingJointAge = (incomeStartAge - primaryAge) + jointAge;
            }
        } else {
            startingPrimaryAge = incomeStartAge;
            deferralTimeYrs = (incomeStartAge - primaryAge);
        }

        return {
            startingPrimaryAge,
            startingJointAge,
            deferralTimeYrs
        };
    }

    static GENERATE_ILLUSTRATION_REQUEST_FROM_FORM(request): MatDialogConfig {

        const {formGroup, annuityProduct} = request;

        const matDialogConfig = new MatDialogConfig();
        matDialogConfig.maxWidth = '100vw';
        matDialogConfig.panelClass = ['no-padding-dialog', 'summary-dialog'];

        const initialPremiumControl = formGroup.get('initialPremiumControl');
        const ageControl = formGroup.get('ageControl');
        const sexControl = formGroup.get('sexControl');
        const incomeRiderToggleControl = formGroup.get('incomeRiderToggleControl');
        const incomeRiderSelectControl = request.riderControl || formGroup.get('incomeRiderSelectControl');
        const incomeStartAgeControl = formGroup.get('incomeStartAgeControl');
        const jointToggleControl = formGroup.get('jointToggleControl');
        const jointSexControl = formGroup.get('jointSexControl');
        const jointAgeControl = formGroup.get('jointAgeControl');

        const incomeRiderSelectedAndToggled = (incomeRiderToggleControl.value && incomeRiderSelectControl.value);
        const jointAccount = incomeRiderSelectedAndToggled && jointToggleControl?.value;

        const incomeStartDate = !incomeRiderSelectedAndToggled ? 'N/A'
          : moment().add(request.incomeStartAges.deferralTimeYrs, 'years').format('MM/DD/YYYY');

        const annuityIllustrationSummaryModel = new AnnuityIllustrationSummaryModel();
        annuityIllustrationSummaryModel.carrier = annuityProduct.carrier;
        annuityIllustrationSummaryModel.product = annuityProduct.productName;
        annuityIllustrationSummaryModel.initialPremium = Utilities.stripAndFormatCurrency(initialPremiumControl?.value, 0);
        annuityIllustrationSummaryModel.age = ageControl?.value;
        annuityIllustrationSummaryModel.sex = Utilities.capitalizeFirstLetter(sexControl?.value);
        annuityIllustrationSummaryModel.incomeRider = incomeRiderSelectedAndToggled ? 'Yes' : 'None';
        annuityIllustrationSummaryModel.incomeRiderCode = incomeRiderSelectControl?.value?.riderId;
        annuityIllustrationSummaryModel.incomeStartAge = incomeRiderSelectedAndToggled ? incomeStartAgeControl?.value : null;
        annuityIllustrationSummaryModel.payoutType = jointAccount ? 'Joint' : 'Single';
        annuityIllustrationSummaryModel.jointSex = jointAccount ? Utilities.capitalizeFirstLetter(jointSexControl?.value) : 'N/A';
        annuityIllustrationSummaryModel.jointAge = jointAccount ? jointAgeControl?.value : 'N/A';
        annuityIllustrationSummaryModel.incomeStartDate = incomeStartDate;
        annuityIllustrationSummaryModel.allocations = request.allocations;
        annuityIllustrationSummaryModel.annuityParams = request.illustrationParams;
        annuityIllustrationSummaryModel.state = request.state?.value;
        annuityIllustrationSummaryModel.cusip = annuityProduct.vendorUniqueId;

        matDialogConfig.data = {
            annuityIllustrationSummaryModel,
        };

        return matDialogConfig;
    }

    static GET_ALLOCATED_BUCKET_RATES(allocationTable: AnnuityAllocationTableModel): (RateGroupData | RateGroup)[] {
        return allocationTable.tableData.filter((row: RateGroup) => AnnuityUtils.isRateGroup(row) && row.allocation > 0);
    }

    static GET_ALLOCATED_RATES(allocationTable: AnnuityAllocationTableModel): (RateGroupData | RateGroup)[] {
        return allocationTable.tableData.filter((row: RateGroupData) => AnnuityUtils.isRateGroupData(row) && row.allocation > 0);
    }

    static GET_ALLOCATED_RATE_KV(allocationTable: AnnuityAllocationTableModel): {[allocationRateKey: string]: number} {
        return this.GET_ALLOCATED_RATES(allocationTable).reduce((previousValue: any, futureRate: RateGroupData) => {
            previousValue[(futureRate.data as AnnuityRate).rateKey] = +futureRate.allocation;
            return previousValue;
        }, {});
    }

    static MAP_SEX_FOR_REQUEST = (sex: string) => sex?.toLowerCase() === 'male' ? 'M' : 'F';

    static momentOffset = (indexDateRange: Moment) => {
        const utcOffset = indexDateRange.utcOffset();
        // TODO - could probably be simplified by just adding a day
        // TODO - maybe check for offset ranges with .utc()
        if (indexDateRange.clone().utc().date() !== indexDateRange.date()) {
            if (utcOffset < 0) {
                indexDateRange.subtract(utcOffset, 'minutes');
            } else {
                indexDateRange.add(utcOffset, 'minutes');
            }
        }
        return indexDateRange.startOf('month');
    }

    static getMinAndMaxAgesFromProducts(useForExchange: boolean, ...annuityProducts: GeneralAnnuityProduct[]) {
        let minAge = useForExchange ? EXCHANGE_MIN_AGE : 0;
        let maxAge = useForExchange ? EXCHANGE_MAX_AGE  : 120;
        annuityProducts.forEach(annuityProduct => {
            if (annuityProduct.minAge > minAge) {
                minAge = annuityProduct.minAge;
            }

            if (annuityProduct.maxAge < maxAge) {
                maxAge = annuityProduct.maxAge;
            }
        });

        return {
            minAge,
            maxAge
        };
    }

    static getMinPremium(...annuityProducts: GeneralAnnuityProduct[]) {
        let minPremium = 0;
        annuityProducts.forEach(annuityProduct => {
            if (+annuityProduct.minPremium > minPremium) {
                minPremium = +annuityProduct.minPremium;
            }
        });

        return minPremium;
    }

    static FORMAT_SURRENDER_YEARS(surrenderYears): string {
        let formattedSurrenderPeriod;
        if (surrenderYears) {
            if (surrenderYears === 1) {
                formattedSurrenderPeriod = 'Annual';
            } else {
                formattedSurrenderPeriod = `${surrenderYears} Years`;
            }
        } else {
            formattedSurrenderPeriod = 'None';
        }
        return formattedSurrenderPeriod;
    }

    static xAxisFormatter = function() {
        const firstSeries = this.chart.userOptions.series[0].data;
        const pointFound = firstSeries?.find(p => p.x === this.value);
        return pointFound?.label ? pointFound?.label : this.axis.defaultLabelFormatter.call(this);
    };

    static createExchangeRequest(request) {
        return {
            ...request,
            id: 'exchange-placeholder-obj'
        };
    }

    static getOrderEntrySystems() {
        return Object.values(this.ORDER_ENTRY_SYSTEM_PREFIXES);
    }

    static convertAnnuityIndicesToMap(annuityIndices: AnnuityIndicesModel[]): Map<string, AnnuityIndicesModel> {
        return new Map<string, AnnuityIndicesModel>(annuityIndices.map(index => [index.code, index]));
    }

    static getDatePickerErrorMsg(formControl: AbstractControl) {
        const {matDatepickerParse, required, matDatepickerMax, matDatepickerMin} = formControl?.errors;
        if(matDatepickerParse) {
            return 'Not a valid date';
        }

        if(required) {
            return 'Date is required';
        }

        if (matDatepickerMax) {
            const date = moment(matDatepickerMax.max).format('MM/DD/YYYY');
            return `Max Date: ${date}`;
        }

        if(matDatepickerMin) {
            const date = moment(matDatepickerMin.min).format('MM/DD/YYYY');
            return `Min Date: ${date}`;
        }

        return ''; // TODO - default?
    }

    static validateExchangeAge(primaryAge, jointAge?): { valid: boolean, [key: string]: boolean} {
        let valid = false;
        const validateAge = (age) => {
            if (isNaN(age)) {
                return null;
            }

            if (age < EXCHANGE_MIN_AGE) {
                return -1;
            }

            if (age > EXCHANGE_MAX_AGE) {
                return 1;
            }

            return 0;
        };

        if (primaryAge) {
            valid = validateAge(primaryAge) === 0;
        }

        if (valid && jointAge) {
            valid = validateAge(jointAge) === 0;
        }

        return {
            valid,
            primaryAgeValid: false,
            jointAgeValid: false
        };
    }

    static validateStrategyLimit(allocationTable: AnnuityAllocationTableModel) {
        const strategyCount = AnnuityUtils.GET_ALLOCATED_RATES(allocationTable)?.length;
        return strategyCount <= EXCHANGE_STRATEGY_MAX;
    }

    static createDisclaimerMessage(selectedChart?, allocationTable?: AnnuityAllocationTableModel, emptyAnnuityIndices?: string[]) {
        if (selectedChart !== 'eff-front') {
            return 'DISCLAIMER: For Financial Professional Use Only.';
        } else {
            return allocationTable.tableData.some((r) =>
                AnnuityUtils.isRateGroup(r) && emptyAnnuityIndices.includes(r?.title?.substring(0, r?.title?.length - 2)))
              ? '*DISCLAIMER: This index does not have sufficient historical and/or backtested performance to be included in the Efficient Frontier graph.'
              : '';
        }
    }

    static configureNewDates(chartFromDateControl: FormControl, chartToDateControl: FormControl, minFromDate, maxFromDate ): boolean {
        let displayError = false;
        if (chartFromDateControl.value < minFromDate) {
            chartFromDateControl.enable();
            chartFromDateControl.setValue(minFromDate);
            chartFromDateControl.markAsTouched();
            chartFromDateControl.updateValueAndValidity();
            displayError = true;
        }

        if (chartToDateControl.value > maxFromDate) {
            chartToDateControl.enable();
            chartToDateControl.setValue(maxFromDate);
            chartToDateControl.markAsTouched();
            chartToDateControl.updateValueAndValidity();
            displayError = true;
        }

        return displayError;
    }

    static createOrderEntryMatDialogConfig(animated = true) {
        const orderEntryMatDialogConfig = new MatDialogConfig() as AnimatedDialogConfig;
        orderEntryMatDialogConfig.disableClose = true;
        orderEntryMatDialogConfig.autoFocus = false;
        orderEntryMatDialogConfig.panelClass = ['no-padding-dialog', 'dialog-1400px', 'no-border-radius-dialog', 'mat-dialog-background-gray'];
        orderEntryMatDialogConfig.maxHeight = '100vh';
        orderEntryMatDialogConfig.height = '100vh';
        orderEntryMatDialogConfig.maxWidth = '100vw';
        if (animated) {
            orderEntryMatDialogConfig.animation = {
                basicAnimation: AnimationDirections.RIGHT
            };
        }
        return orderEntryMatDialogConfig;
    }

    static isRiderValid(primaryAge: number, jointAge: number, annuityRider: (AnnuityRider | DeathBenefitRider)) {
        const checkIfAgeIsValid = (age) => {
            return (+age <= +annuityRider.issueMaxAge && +age >= +annuityRider.issueMinAge);
        };

        // It is assumed that these values come in pairs, if one or both are undefined, allow DB Rider through
        if (annuityRider.issueMaxAge == null || annuityRider.issueMinAge == null) {
            return true;
        }

        return !!jointAge ? checkIfAgeIsValid(jointAge) && checkIfAgeIsValid(primaryAge) : checkIfAgeIsValid(primaryAge);
    }

    static filterOutInvalidRiders<T extends AnnuityRider | DeathBenefitRider>(riderControl: FormControl, primaryAge: number, jointAge: number, annuityRiders: T[]): T[] {
        const riderSelected = riderControl.value;
        let riderFilteredOut = false;

        const filteredRiders = annuityRiders.filter((rider: T) => {
            const validRider = this.isRiderValid(primaryAge, jointAge, rider);
            if (!riderFilteredOut && !validRider) {
                riderFilteredOut = rider instanceof AnnuityRider || 'riderId' in rider
                  ? rider?.riderId != null && rider.riderId === riderSelected?.riderId
                  : rider?.dbRiderId != null && rider.dbRiderId === riderSelected?.dbRiderId;
            }
            return validRider;
        });

        if (riderFilteredOut) {
            const setRider = filteredRiders.length === 1 && riderControl.hasValidator(Validators.required);
            const lbValue = setRider ? filteredRiders[0] : '';
            riderControl.markAsTouched({onlySelf: true});
            riderControl.setValue(lbValue);
            riderControl.updateValueAndValidity();
        }

        return filteredRiders;
    }

    static isShellProduct(annuityProduct: GeneralAnnuityProduct, defaultVal = false): boolean {
        return annuityProduct?.vendorUniqueId?.startsWith(ANNUITY_SHELL_PRODUCT) || defaultVal;
    }
}
